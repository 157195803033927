<template>
  <div id="app"> 
      <router-view /> 
  </div>
</template>
<script>    
export default { 
    created() { 
  },
};
</script>
<style>
#app {
    font-family: '微软雅黑';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
html,
body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

</style>
